'use strict'
const values_ = require('lodash/values')
const { init: initSiteMonitoring } = require('./siteMonitoring')
const { buildNamespacesMap } = require('./buildNamespacesMap')

const SCRIPT_ERROR_MESSAGE = 'There was an error in your script'
const DISABLE_USER_CODE_QUERY_PARAM = 'wixCodeDisableUserCode'

function _shouldDisableUserCode(wixSdk) {
  const query = wixSdk.location.query || {}
  return query[DISABLE_USER_CODE_QUERY_PARAM] === 'true'
}

const reportErrorsFromStaticEventHandlers = ({
  appLogger,
  userConsole,
  modules
}) => {
  // Log errors from static event handlers, because
  // they are reported as "Script error" when
  // caught using the "error" global event handler
  try {
    modules.forEach(module => {
      Object.keys(module || {}).forEach(key => {
        const originalFn = module[key]
        module[key] = function(...args) {
          try {
            return originalFn(...args)
          } catch (e) {
            // we log the error from our domain instead of throwing it, so we'll
            // get a proper error and stack trace instead of "Script Error"
            userConsole.error(e)
          }
        }
      })
    })
  } catch (e) {
    appLogger.error(e)
  }
}

function runUserCode({
  userConsole,
  appLogger,
  fedopsLogger,
  wixSdk,
  userCodeModules,
  wixCodeScripts,
  instance,
  onLog
} = {}) {
  try {
    if (_shouldDisableUserCode(wixSdk)) {
      return
    }

    const loadingCodeMessages = wixCodeScripts.reduce((acc, { script }) => {
      acc[script.scriptName] = `Loading the code for the ${
        script.displayName
      }. To debug this code, open ${script.scriptName} in Developer Tools.`

      return acc
    }, {})

    initSiteMonitoring({
      appLogger,
      fedopsLogger,
      wixSdk,
      instance,
      onLog,
      ignoredConsoleMessages: values_(loadingCodeMessages)
    })

    const modules = wixCodeScripts.map(({ script, $w }) => {
      if (userConsole && userConsole.info) {
        userConsole.info(loadingCodeMessages[script.scriptName])
      }

      let module = {}
      if (!userCodeModules.has(script.url)) {
        appLogger.warn(
          `Trying to run a user code script which was not loaded`,
          {
            extra: {
              script
            }
          }
        )
        return
      }

      try {
        const moduleFunc = userCodeModules.get(script.url)
        module = moduleFunc && moduleFunc($w, buildNamespacesMap(wixSdk))
      } catch (e) {
        userConsole.error(SCRIPT_ERROR_MESSAGE)
        userConsole.error(e)
      }

      return module
    })

    reportErrorsFromStaticEventHandlers({ appLogger, userConsole, modules })

    return modules.reduce((r, c) => Object.assign(r, c), {})
  } catch (e) {
    appLogger.error(e)
    throw e
  }
}

module.exports = {
  runUserCode
}
