const reduce_ = require('lodash/reduce')

const buildNamespacesMap = wixSdk =>
  reduce_(
    wixSdk,
    (acc, sdk, namespace) => {
      switch (namespace) {
        case 'events':
          // do nothing - namespace events is internal
          break
        case 'user':
          acc['wix-users'] = sdk
          break
        case 'wixEvents':
          acc['wix-events'] = sdk
          break
        default:
          acc[`wix-${namespace}`] = sdk
      }

      return acc
    },
    {}
  )

module.exports = {
  buildNamespacesMap
}
