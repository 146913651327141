'use strict'

const isError_ = require('lodash/isError')

const { wixCodeLogLevel } = require('./wixCodeLogLevel')
const { callbackRegistrar } = require('./callbackRegistrar')

const consoleMethodsToWrap = {
  info: wixCodeLogLevel.INFO,
  warn: wixCodeLogLevel.WARN,
  error: wixCodeLogLevel.ERROR,
  log: wixCodeLogLevel.LOG,
  debug: wixCodeLogLevel.DEBUG,
  assert: wixCodeLogLevel.ASSERT,
  dir: wixCodeLogLevel.DIR,
  table: wixCodeLogLevel.TABLE,
  trace: wixCodeLogLevel.TRACE
}

function _normalizeCollection(collection, seen) {
  seen = seen || []
  if (collection instanceof Map) {
    const clone = ['[Map]']
    collection.forEach((v, k) => clone.push([_normalize(k), _normalize(v)]))
    return clone
  }
  if (collection instanceof Set) {
    const clone = ['[Set]']
    collection.forEach(v => clone.push(_normalize(v)))
    return clone
  } // it is arguments
  else return Array.prototype.map.call(collection, v => _normalize(v, seen))
}

function _normalize(v, seen) {
  seen = seen || []
  if (v === null || v === undefined) return v
  else if (
    v instanceof Error ||
    v instanceof Date ||
    typeof v === 'symbol' ||
    typeof v === 'function'
  ) {
    return v.toString()
  } else if (Array.isArray(v) || v instanceof Map || v instanceof Set) {
    if (seen.includes(v)) return '[Circular]'
    seen.push(v)
    const arrClone = _normalizeCollection(v, seen)
    seen.pop()
    return arrClone
  } else if (typeof v.then === 'function') {
    return `Promise<>`
  } else if (typeof v === 'object') {
    seen.push(v)
    const vClone = Object.keys(v).reduce((clone, key) => {
      const memberValue = v[key]
      if (seen.includes(memberValue)) clone[key] = '[Circular]'
      else clone[key] = _normalize(memberValue, seen)
      return clone
    }, {})
    seen.pop()
    return vClone
  } else return v
}

function wrapConsoleMethod(consoleInstance, logLevel, originalFunc, onLog) {
  return function() {
    const stack = isError_(arguments[0])
      ? arguments[0].stack
      : new Error().stack
    const args = _normalizeCollection(arguments)
    const messageData = {
      logLevel,
      args,
      stack
    }
    onLog(messageData)
    originalFunc.apply(consoleInstance, arguments)
  }
}

function wrapConsole(consoleInstance) {
  const { register: onLog, call: callOnLogListeners } = callbackRegistrar()

  if (consoleInstance) {
    const originalLog = consoleInstance.log || (() => {})
    for (const method in consoleMethodsToWrap) {
      if (
        consoleMethodsToWrap.hasOwnProperty(method) &&
        consoleInstance.hasOwnProperty(method)
      ) {
        consoleInstance[method] = wrapConsoleMethod(
          consoleInstance,
          consoleMethodsToWrap[method],
          consoleInstance[method],
          callOnLogListeners
        )
      }
    }
    consoleInstance.verbose = wrapConsoleMethod(
      consoleInstance,
      wixCodeLogLevel.VERBOSE,
      originalLog,
      callOnLogListeners
    )

    //TODO: remove right after WCD-6723 is complete
    consoleInstance.serverLog = () => {}
  }

  return onLog
}

function getMessageFromErrorObject(error) {
  return error.message || error.name
}

function handlePromiseRejections() {
  const onUnhandledRejection = callback => {
    const listener = event => {
      const { reason } = event
      const error = typeof reason === 'object' ? reason : { message: reason }

      callback({
        args: [getMessageFromErrorObject(error)],
        logLevel: 'ERROR',
        stack: error.stack
      })
    }
    self.addEventListener('unhandledrejection', listener)
  }
  return onUnhandledRejection
}

module.exports = {
  wrapConsole,
  handlePromiseRejections
}
